/* GraphicDesignSection.css */
.graphic-design-section {
    background-color: #fff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
  }
  
  .graphic-title {
    font-size: 56px;
    color: #0E009D;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .nav-Tabs {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
  }
  
  .nav-tab {
    font-size: 12px;
    font-weight: 500;
    color: #312948B2;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
  }
  
  .nav-tab.active,
  .nav-tab:hover {
    color: #0E009D;
  }
  
  .nav-tab.active::after,
  .nav-tab:hover::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #6d4c41;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .graphic-description {
    font-size: 29px;
    font-weight: 600;
    color: #000000B2;
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    line-height: 54px;
  }
  

  @media screen and (max-width: 600px) {
    .graphic-title {
      font-size: 28px;
    }
    .graphic-description{
      font-size: 15px;
      line-height: 21px;
    }
  }