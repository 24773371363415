.work-library {
  text-align: center;
  padding: 3rem 1rem; /* Add padding on smaller screens */
  color: #312948B2;
}

.title {
  font-size: 56px;
  font-weight: 700;
  color: #0E009D;
}

.description {
  font-size: 26px;
  font-weight: 700;
  color: #312948B2;
  margin-bottom: 2rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.5;
}

.grid-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Make columns responsive */
  max-width: 1600px;
  margin: 0 auto;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image-placeholder  {
  width: 100%;
  height: 200px; /* Smaller default height for responsiveness */
  background-color: #e0e0e0;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-item p {
  margin-top: 0.5rem;
  font-weight: bold;
}

/* Responsive breakpoints */
@media (min-width: 768px) {
  .title {
    font-size: 3rem;
  }

  .description {
    font-size: 1.3rem;
  }

  .image-placeholder {
    height: 300px;
  }
}

@media (min-width: 1024px) {
  .description {
    font-size: 1.4rem;
  }

  .grid-container {
    gap: 3rem;
   
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Larger columns on wider screens */
  }

  .image-placeholder {
    height: 340px;
  }
}

@media (min-width: 1440px) {
  .title {
    font-size: 3.5rem;
  }

  .description {
    font-size: 1.4rem;
  }

  .grid-container {
    gap: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  }

  .image-placeholder {
    height: 340px;
  }
}

@media screen and (max-width: 600px) {
  .title{
    font-size: 24px;
  }
  .description{
    font-size: 12px;
  }
  .grid-container{
    margin: 0px 0px 0px 30px;
  }
}