/* TermsAndConditions.css */
.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #000000B2;
    font-family: Montserrat;
}

.terms-container h1 {
    font-size: 56px;
    margin-top: 30px;
    font-weight: 700;
    color: #0E009D;
    margin-bottom: 40px;
    text-align: center;
}


.terms-container-p {
    line-height: 40px;
    padding: 50px;
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 400;
    color: #000000CC;
}
