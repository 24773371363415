/* ImageGrid.css */
.custom-img {
    width: 100%;  /* Adjust width to fit container */
    height: 272px; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.custom-img:hover {
    transform: scale(1.05);
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.col-6, .col-md-3 {
    padding-left: 15px;
    padding-right: 15px;
}
