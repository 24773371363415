.heading {
    font-weight: 700;
    font-size: 56px;
    line-height: 76px;
    text-align: left;
      color: #0E009D; /* Brown color for the text */
  
    }
  
    .contact-info{
      margin-top: 59px;
    }
    .h-para{
      font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  width: 479px;
  height: 82px;
  gap: 0px;
  color: #312948B2;
  
  text-align: left;
  
    }
    .btn-message {
      background-color: #4D3EE9; /* Brown color for the buttons */
      color: white;
      border-radius: 6px;
      height: 61px;
      font-weight: 600;
      font-size: 18px;
  
    }
    .btn-submit{
      background-color: #4D3EE9;
      color: white  ;
        font-size: 18px;
        font-weight: 600;
        width: 222px;
        height: 61px;
        top: 18px;
    }
    .btn-message:hover,
    .btn-submit:hover{
      color: white;
      background-color: #0E009D;
    }
    .form-container{
      width: 640px;
      height: 740px;
      margin:  60px;
  
      
    }
    .bg-beige {
      background-color: #F2E7FE; /* Beige color for the form background */
    }
    
  .form-controll{
    
      width: 560px;
      height: 61px;
      color: #9B9B9BCC;
      font-size: 18px;
      font-weight: 600;
      /* opacity: 80%; */
  }
    .form-label{
      font-weight: 600;
      font-size: 22px;
      line-height:40px;
    }
  
    .contact-details-para {
      margin: 28px;
      text-align: left;
      font-size: 32px;
      font-weight: 600; 
      color: #0E009D;
      /* line-height: 40px; */
    }
  
    .contact-details-para a{
      color: #000000E5;
    }
  
    .contact-details{
      margin-top: 90px;
    }
  
   /* media query  */
  
  
   /* General Responsive Styles for Smaller Screens */
  @media (max-width: 768px) {
    
  
    /* Contact Info and Details Section */
    .contact-info, .contact-details {
      
      margin-top: 30px;
    }
  
    .heading {
      /* font-size: 1.75rem; */
    }
  
    .h-para,
    .contact-details-para,
    .form-controll {
      font-size: 24px;
    }
  
  
    .form-container {
      margin-top: 20px;
    }
  }
  
  /* Extra Small Screens (max-width: 576px) */
  @media (max-width: 576px) {
      /* .container {
        padding: 10px;
      } */
  
    .heading {
      font-size: 28px;
      margin: 0;
    }
  
    .h-para{
      font-size: 15px;
      line-height: 18.75px;
      width: 363px;
    }
    /* .contact-details-para,
    .btn-message,
    .form-controll,
    .btn-submit  */
  
    .btn-message{
      /* padding: 10px; */
      margin: 0;
      width: 114px;
      height: 29px;
      font-size: 10px;
    }
  
    .btn-submit{
      width: 322px;
      height: 33px;
      font-size: 10px;
    }
    .form-controll {
      padding: 10px;
      font-size: 9px;
      width: 322px;
      height: 33px;
    }
    .contact-info{
      margin: 0;
    }
    .bg-beige{
      width: 368px;
      height: 380px;
      margin-left: 30px
    } 
    .form-label{
      line-height: 0;
      font-size: 10px;
    }
    .form-container{
      margin: 0;
      height: auto;
    }
  }
  