.footer {
    background-color: #0E009D;
    padding:20px 120px 30px 120px;
    color: #fff;
    
  }
  
  .footer-content h2 {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .footer-buttons {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .demo-btn,
  .contects-btn {
    background-color: #fff;
    color: #4D3EE9;
    border: none;
    padding: 15px 20px;
    font-size: 1rem;
    margin: 0 0 0 20px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    width: 170px;
  }
  
  .demo-btn:hover{
   
    color: #fff;
    outline:2px solid white;
    background-color: #0E009D ;
    
  }
  
  .contects-btn:hover {
    background-color: #0E009D;
    outline:2px solid white;
    color: white;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 30px 0;
    border-top: 1px solid #B7B7B7;
  }
   
  .footer-bottom{
  border-top: 1px solid #B7B7B7;
  }
  
  .footer-logo {
    margin-bottom: 10px;
  }
  
  .logo-part {
    color: #d0a475;
  }
  
  .footer-logo-address {
    flex-basis: 40%;
    font-size: 32px;
    margin-top: 30px;
  }
  
  .footer-logo-address p {
    margin: 0px 0;
    font-size: 24px;
    font-weight: 600;
    width: 400px;
  }
  
  .footer-links {
    padding-top: 50px ;
    display: flex;
    flex-basis: 50%;
    justify-content: space-around;
    font-size: 18px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
    color: white;
    cursor: pointer;
   text-decoration: none;
    
  }
  
  .p{
    padding: 40px 0;
  }
  
  p.copyright {
    margin-top: -20px;
    text-align: center;
    color: #ffffff;
    font-size: 15px;
    font-weight: 100;
  }
  
  
  .footer-links ul li:hover {
    text-decoration: underline white;
  
  }
  
  @media screen and (max-width: 1240px) {
    .footer-links ul li{
      font-size: 9px;
      line-height: 7.2px;
    }
    .footer-logo-address p{
      font-size: 12px;
      font-weight: 600;
      width: 200px;
      line-height: 16px;
    }
    .footer-logo-address{
      margin-top: 10px;
    }
    .demo-btn,
    .contects-btn {

      font-size: 10px;
      font-weight: 700;
      width: 100px;
      padding: 7px 10px;
    }
    .footer-content h2{
      font-size: 20px;
      margin-bottom: 16px;
    }
    .footer-buttons{
      margin-bottom: 40px;
    }
    .footer{
      padding: 40px 80px 0px 80px;
    }
    .footer-bottom{
      padding: 16px 0;
    }
    .footer-links{
      padding-top: 26px ;
    }
    .footer-logo{
        width: 133px;
        height: 42px;
    }
  
    p.copyright {
      padding-top: 16px;
      font-size: 8px;
      font-weight: 100;
    }
  
  
  }
  
  
  
  /* On screens that are 600px or less, set the background color to olive */
  @media screen and (max-width: 600px) {
    .footer-links ul li{
      font-size: 6px;
      line-height: 7.2px;
    }
    .footer-logo-address p{
      font-size: 8px;
      font-weight: 600;
      width: 140px;
      line-height: 9.2px;
    }
    .footer-logo-address{
      margin-top: 10px;
    }
    .demo-btn,
    .contects-btn {
      font-size: 9px;
      font-weight: 700;
    
      padding: 7px 10px;
    }
    .footer-content h2{
      font-size: 14px;
      margin-bottom: 10px;
    }
    .footer-buttons{
      margin-bottom: 20px;
    }
    .footer{
      padding: 30px 40px 0px 40px;
    }
    .footer-bottom{
      padding: 10px 0;
    }
    .footer-links{
      padding-top: 20px ;
    }
    .footer-logo{
        width: 63px;
        height: 22px;
    }
    .dune1 {
      width: 100%;
      margin-top: -46px;
    }
    p.copyright {
      padding-top: 14px;
      font-size: 6px;
      font-weight: 100;
    }
  
  }