/* Blogbody.css */
.card {
    border: none;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card-img-top {
    height: 211px;
    object-fit: cover;
  }
  
  .card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 0.95rem;
    flex-grow: 1;
  }
  
  .card-meta {
    font-size: 0.8rem;
    color: #6c757d;
  }
  