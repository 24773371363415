.custom-img {
    width: 100%; /* Adjust width to fit container */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.custom-img:hover {
    transform: scale(1.05);
}

.flyerrow {
    margin-left: -15px;
    margin-right: -15px;
}

.flyerimage {
    padding-left: 15px;
    padding-right: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .flyerimage {
        flex: 0 0 50%; /* Display 2 columns for medium screens */
        max-width: 50%;
    }
}

@media (max-width: 576px) {
    .flyerimage {
        flex: 0 0 100%; /* Display 1 column for small screens */
        max-width: 100%;
    }

    .custom-img {
        height: auto; /* Ensure images maintain aspect ratio */
    }
}
